import React, { useEffect, useState } from "react";
import './style.css'
import { useParams } from "react-router-dom";
import { Project } from "./config";
import { getProjectInfo } from "./helpers";
import AppointmentButton from "../../components/appointmentButton/AppointmentButton";

function ProjectPage() {
    const { id } = useParams();
    const [projectInfo, setProjectInfo] = useState<Project | undefined>();

    useEffect(() => {
        if (id) {
            setProjectInfo(getProjectInfo(id));
        }
    }, [id])

    return (
        <>
            {projectInfo && (
                <div className="projects_wrapper">
                    <div className="project_header">
                        <img src={projectInfo.image} alt=" "/>
                        <p className="project_title">{projectInfo.title}</p>
                    </div>
                 
                    <div className="projects_info">
                        <p>{projectInfo.text}</p>
                        <AppointmentButton/>
                    </div>
                   
                </div>
            )}
        </>

    )
}

export default ProjectPage