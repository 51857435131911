import React, { useEffect } from "react";
import './style.css'
import Links from "../../components/links/Links";

function ContactPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="contact_wrapper">
            <p className="contact_us">Свяжитесь с нами!</p>
            <div className="contacts_info">
                <div className="contact_links">
                    <Links />
                </div>

                {/* <p>+375 33 385 28 58</p> */}
                <p>+375 44 799 30 08</p>
                <p>felicia29@mail.ru</p>

            </div>
        </div>
    )
}

export default ContactPage