import React from "react";
import './style.css'
import { DiagnosticListType } from "../../config";

function DiagnosticCard({ item }: { item: DiagnosticListType }) {
    return (
        <div className="d_card">
            <div className="d_header">
                <h3>{item.diagnostic}</h3>


            </div>
            <div className="d_body">
                <ul className="d_types">
                    {item?.items.map((type, index) => (
                        <li key={index}>{type}</li>
                    ))}
                </ul>
                {item.description &&
                     item.description.map((descr, index)=>(
                        <p className="d_descr">{descr}</p>
                     ))}
            </div>
        </div>
    )
}
export default DiagnosticCard;