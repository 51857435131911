import React, { useState } from "react";
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import "./style.css"
import { DirectionsType } from "../../../../data/directions";


function DropdownBox({ item }: { item: DirectionsType }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div className="dropdown_wrapper">
            <div className="direction" onClick={toggleExpansion} >
                <p>{item.type}</p>
                <div onClick={toggleExpansion}>
                    {isExpanded ? <RiArrowUpSFill  className='direction_arrow' color="#043c2b" /> : <RiArrowDownSFill    className='direction_arrow' color="#043c2b" />}
                </div>
            </div>
            {isExpanded && (
                <div className="direction_info">

                    <ul className="direction_list">
                        {item.descriptions.map((description, index) => (
                            <li key={index}>{description}</li>
                        ))}
                    </ul>

                    {/* <p>Дополнительная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.ельная информация о нашем центре и предлагаемых услугах.</p> */}
                </div>
            )}



        </div>

    )
}
export default DropdownBox