import React from "react";
import './Table.css'
import problems from '../../../../data/problems.json'
import ProblemIcon from "../problemIcon/ProblemIcon";

function Table() {
    return (
        <div className="problems_wrapper">
            <p className="title">Проблемы, c которыми мы работаем</p>
            <div className="problems_table_wrapper">
                {problems.map((item)=>(
                  <ProblemIcon key={item.id} {...item}/>
                ))}
            </div>

        </div>
    )
}

export default Table;