import React from "react";
import './style.css'
import { PsychologistType } from "../../types";
import { useAppDispatch } from "../../../../store/hooks";
import { setID } from "../../../../store/slices/psychID";

function Card({ id, name, post, photo }: PsychologistType) {

    const dispatch = useAppDispatch();

    function openModal() {
        dispatch(setID(id))
    }
    return (
        <div className="card" onClick={openModal}>
            <img src={photo[0]} alt="" />
            <div className="card_info">
                <p className="name">{name}</p>
                <p className="post">{post}</p>
            </div>
        </div>
    )
}

export default Card