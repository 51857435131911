import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {store} from '../index'

interface IdState {
    id: string | undefined
}

const initialState: IdState = {
    id: undefined,
}
export const IdSlice = createSlice({
    name:'psychID',
    initialState,
    reducers:{
        setID:(state, action:PayloadAction<string | undefined>) =>{
            state.id = action.payload;
        }
    }
})

export const {setID} = IdSlice.actions;
export const getID = (state:store) => state.psychID.id;
export default IdSlice.reducer