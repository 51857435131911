import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainPage from './pages/main/Mainpage';
import PsychologistsPage from './pages/psychologists/PsychologistsPage';
import ContactPage from './pages/contact/ContactPage';
import ServicesPage from './pages/services/ServicesPage';
import FeedbackPage from './pages/feedback/FeedbackPage';
import ProjectPage from './pages/project/ProjectPage';

function App() {
  return (
    <Router>
      <div className='app_wrapper'>
        <Header />
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/psychologists' element={<PsychologistsPage />} />
          <Route path='/contact' element={<ContactPage/>}/>
          <Route path='/services' element={<ServicesPage/>}/>
          <Route path='/feedback' element={<FeedbackPage/>}/>
          <Route path="/projects/:id" element={<ProjectPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>

  );
}

export default App;
