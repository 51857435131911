import React, { useEffect, useState } from "react";
import './style.css'
import Card from "./components/card/Card";
// import { psychologists } from "./config";
import CustomModal from "./components/modal/CustomModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setID } from "../../store/slices/psychID";
import psychologists from '../../data/psychologists.json'

function PsychologistsPage() {

    const id = useAppSelector((state) => state.psychID.id)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        dispatch(setID(undefined))
        
    };
    useEffect(() => {
        if(id) {
            openModal()}
    }, [id])

    return (
        <div className="box">
            {/* <div className="modal_wrapper">
                <div className="modal">

                </div>
            </div> */}
            <CustomModal isOpen={isModalOpen} onClose={closeModal} />
            <div className="cards">
                <div className="cards-wrapper">
                    {psychologists.map((item) => (
                        <Card key={item.id} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PsychologistsPage