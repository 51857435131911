import React from "react";
import './style.css'
import { PriceListType, classNames } from "../../config";



function TableLine({ item, index }: { item: PriceListType; index: number }){
    const lineClassName = index % 2 === 0 ? classNames.odd : classNames.even;
    return(
       <div className={lineClassName}>
        <div className="table-cell">{item.service}</div>
        <div className="table-cell">{item.time}</div>
        <div className="table-cell">{item.price}</div>
       </div>
    )
}
export default TableLine