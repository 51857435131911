export interface Project{
    id: string,
    title: string,
    image: string,
    text: string,
}


export const recentProjects : Project[] =[
    {
        id: 'neurography',
        title: 'Нейрорафика как способ организации мышления',
        image: require('../img/projectCard2.png'),
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur turpis dolor, convallis eget quam nec, lacinia vestibulum tortor. Phasellus ultricies nunc et lacus ultrices, at convallis urna porta. Nullam sodales, erat eget ultrices sollicitudin, justo urna aliquam erat, ac consectetur orci leo sed enim. Nam pharetra volutpat ultrices. Vestibulum dignissim enim at rutrum condimentum. Nunc sit amet mauris vel ipsum dictum consectetur. Vivamus ligula tellus, eleifend sed velit a, lacinia sodales diam. Nulla pharetra lobortis nisl, nec maximus dolor malesuada eu. Nulla elit sapien, ullamcorper vel egestas quis, sodales vitae diam. Nulla luctus tellus sit amet ipsum tristique tincidunt. Curabitur tempus risus turpis, vel facilisis ante dignissim non.',
    },
    {
        id: 'item2',
        title: 'Оценка привязанности: гарантия благополучия ребенка при разводе',
        image: require('../img/projectCard4.png'),
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur turpis dolor, convallis eget quam nec, lacinia vestibulum tortor. Phasellus ultricies nunc et lacus ultrices, at convallis urna porta. Nullam sodales, erat eget ultrices sollicitudin, justo urna aliquam erat, ac consectetur orci leo sed enim. Nam pharetra volutpat ultrices. Vestibulum dignissim enim at rutrum condimentum. Nunc sit amet mauris vel ipsum dictum consectetur. Vivamus ligula tellus, eleifend sed velit a, lacinia sodales diam. Nulla pharetra lobortis nisl, nec maximus dolor malesuada eu. Nulla elit sapien, ullamcorper vel egestas quis, sodales vitae diam. Nulla luctus tellus sit amet ipsum tristique tincidunt. Curabitur tempus risus turpis, vel facilisis ante dignissim non.',
    },
    {
        id: 'item3',
        title: 'Оценка психоэмоционального благополучия через диагностику',
        image: require('../img/projectCard3.png'),
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur turpis dolor, convallis eget quam nec, lacinia vestibulum tortor. Phasellus ultricies nunc et lacus ultrices, at convallis urna porta. Nullam sodales, erat eget ultrices sollicitudin, justo urna aliquam erat, ac consectetur orci leo sed enim. Nam pharetra volutpat ultrices. Vestibulum dignissim enim at rutrum condimentum. Nunc sit amet mauris vel ipsum dictum consectetur. Vivamus ligula tellus, eleifend sed velit a, lacinia sodales diam. Nulla pharetra lobortis nisl, nec maximus dolor malesuada eu. Nulla elit sapien, ullamcorper vel egestas quis, sodales vitae diam. Nulla luctus tellus sit amet ipsum tristique tincidunt. Curabitur tempus risus turpis, vel facilisis ante dignissim non.',
    },

]