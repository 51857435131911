import React from "react";
import './style.css'
import logo from './img/logo.png'
import NavigationMenu from "../navigationMenu/NavigationMenu";
import Links from "../links/Links";


function Header() {

    
    return (
        <>
        <div className="header_container">
            <div className="header">
                <div className="left_parth">
                    <img src={logo} className="logo" alt=""/>
                    <div className="text-box">
                        <p className="header_title">Центр психологической помощи и поддержки семьи Крылья мечты</p>
                        <p className="org">Общество с ограниченной ответсвенностью</p>
                    </div>
                   
                </div>
                <div className="right_part">
                    <div className="contacts">
                        <p>+375 44 799 30 08 А1</p>
                        <p>Полина</p>
                    </div>
                    <Links/>
                </div>

            </div>
           
        </div>
       
        <NavigationMenu/>
        </>
    )
}

export default Header

