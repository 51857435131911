import React from "react";
import { PsychologistType } from "../../../psychologists/types";
import './PsychoCarousel.css'

function PsychoCarouselCard({ id, name, post, photo }: PsychologistType) {
    return (
        <div className="psycho_carousel_card_wrapper">
            <div className="psycho_carousel_card_image">
                <img src={photo[0]} alt='' />
            </div>
            <div className="psycho_carousel_card_info">
                <p className="psycho_carousel_card_name">{name}</p>
                <p className="psycho_carousel_card_post">{post}</p>
            </div>

        </div>
    )
}

export default PsychoCarouselCard;