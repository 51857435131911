import React from 'react'
import './style.css'
// import { Link } from 'react-router-dom';
import { Project } from '../../../project/config';

function ProjectsCard({ project }: { project: Project }) {
    return (
        // <Link to={`/projects/${project.id}`}  className="project_card">
        <div className='project_card'>
            <img src={project.image} alt=' '/>
            <p>{project.title}</p>
        </div>

        // </Link>
    )
}

export default ProjectsCard;