import { configureStore } from '@reduxjs/toolkit'
import psychIdReducer from './slices/psychID';

export const store = configureStore({
    reducer:{
        psychID: psychIdReducer,
    }
})

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type store = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch
export default store