import React from "react";
import Flickity from "react-flickity-component";

import PsychoCarouselCard from "./PsychoCarouselCard";
import psychologists from '../../../../data/psychologists.json'


import 'swiper/css';


function PsychoCarousel() {
    // const item = psychologists[0]

    return (
        <div className='carousel_wrapper'>
            <p className='blok_title'>Наши специалисты</p>
            <Flickity
                className="psycho_carousel"
                options={
                    {
                        initialIndex: 1,
                        prevNextButtons: false,
                        pageDots: false,
                        contain: false,
                        cellAlign: 'left',
                        adaptiveHeight: false,
                        autoPlay: 2000,

                        wrapAround: true,
                        draggable: true,
                    }}>
                {psychologists.map((item) => (
                    <PsychoCarouselCard {...item} />
                ))}
                {psychologists.map((item) => (
                    <PsychoCarouselCard {...item} />

                ))}
            </Flickity>


        </div>
    )

}

export default PsychoCarousel



// {/* <Swiper
// slidesPerView={4}
// spaceBetween={30}
// loop={true}
// pagination={{
//     clickable: true,
// }}
// autoplay={{
//     delay: 3000,
//     disableOnInteraction: false,
// }}
// breakpoints={{
//     640: {
//         slidesPerView: 1,
//         spaceBetween: 20,
//     },
//     906: {
//         slidesPerView: 2,
//         spaceBetween: 30,
//     },
//     1000: {
//         slidesPerView: 4,
//         spaceBetween: 30,
//     },
//     1700: {
//         slidesPerView: 4,
//         spaceBetween: 30,
//     },

// }}

// >
// {psychologists.map((item) => (
//     <SwiperSlide key={item.id}

//     >
//         <PsychoCarouselCard {...item} />
//     </SwiperSlide>

// ))}
// {psychologists.map((item) => (
//     <SwiperSlide >
//         <PsychoCarouselCard {...item} />
//     </SwiperSlide>

// ))}
// </Swiper> */}


// {psychologists.map((item) => (
//     <PsychoCarouselCard {...item} />
// ))}