import React from "react";
import './style.css'
import FeedBackCard from "./feedbackCard/FeedBackCard";
import { reviews } from "../../data/reviews";

function FeedbackPage() {
    return (
        <div className="feedback_wrapper">
            <p className="blok_title">Отзывы</p>
            <div className="reviews">
                {reviews.map((item) => (
                    <FeedBackCard key={item.id} item={item} />
                ))}

            </div>
            {/* <p className="blok_title">Оставьте ваш отзыв!</p> */}
            {/* <FeedbackForm /> */}
        </div>
    )
}

export default FeedbackPage