import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './style.css';
import { RxHamburgerMenu } from "react-icons/rx";
//RxHamburgerMenu
//RxHamburgerMenu

function NavigationMenu() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCostLinkClick = () => {
    setIsMenuOpen(false);
    navigate('/services');
    const element = document.getElementById('cost-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const handleDiagnosticLinkClick = () => {
    setIsMenuOpen(false);
    navigate('/services');
    const element = document.getElementById('diagnostic-section');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <ul className={`nav-links ${isMenuOpen ? 'nav-links-open' : 'nav-links '}`}>
          <li>
            <NavLink to="/" onClick={toggleMenu}>
              Главная
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" onClick={toggleMenu}>
              Услуги
            </NavLink>
          </li>
          <li>
            <a href="#cost-section" onClick={handleCostLinkClick}>
              Стоимость услуг
            </a>
          </li>
          <li>
            <a href="#diagnostic-section" onClick={handleDiagnosticLinkClick}>
              Диагностики
            </a>
          </li>
          <li>
            <NavLink to="/psychologists" onClick={toggleMenu} >
              Психологи
            </NavLink>
          </li>
          <li>
            <NavLink to="/feedback" onClick={toggleMenu}>
              Отзывы
            </NavLink>
          </li>
        </ul>
      
      
      </div>

      <RxHamburgerMenu
        onClick={toggleMenu}
          size={40}
          color='white'
          className='ham' />
    </nav>
  );
}

export default NavigationMenu;