import React from "react";
import './style.css'
import { useNavigate } from "react-router-dom";


function AppointmentButton() {
    const navigate = useNavigate();

    const goToContactPage = () => {
        navigate('/contact');
    };

    return (
        <button className="btn" onClick={goToContactPage}>Записаться на прием</button>
    )
}

export default AppointmentButton