import React from "react";
import './style.css'
import { FeedbackType } from "../../../data/reviews";

function FeedBackCard({ item }: { item: FeedbackType }) {
    return (
        <div className="review_card_wrapper">
            <div className="user_image_box">
                <div className="image">
                    <img src={require('../../../components/header/img/logo.png')} alt=""/>
                </div>

            </div>

            <div className="text">
                <p className="mail">{item.user}</p>
                <p className="date">{item.date}</p>
                <p>{item.review}</p>
            </div>

        </div>
    )
}

export default FeedBackCard