import React from "react";
import './style.css'
import { AiFillInstagram, AiOutlineWhatsApp } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";

function Links() {

    const whatsAppPhoneNumber = '375447993008'


    const openLink =(link: string) => {
        window.open(link,  '_blank')
    }
    return (
        <div className="icons">
            <AiFillInstagram className="icon" size={30} onClick={()=>openLink('https://www.instagram.com/revival.psy/')}/>
            <AiOutlineWhatsApp className="icon" size={30} onClick={()=>openLink(` https://wa.me/${whatsAppPhoneNumber}`)}/>
            <BiLogoTelegram className="icon" size={30} onClick={()=>openLink('https://t.me/Felicia29')} />
        </div>
    )
}

export default Links