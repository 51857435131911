import React from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import Slide from './Slide';
import AppointmentButton from '../../../../components/appointmentButton/AppointmentButton';

function Slider() {

    const flickityOptions = {
        initialIndex: 0,
        wrapAround: true,
        autoPlay: 4000,
        pauseAutoPlayOnHover: true,
        prevNextButtons: false,
        pageDots: false,
    };
    return (

        <div className='slider_wrapper'>
            <Flickity
                className='main_slider'
                options={flickityOptions}>
                <Slide image={require('./img/banner1.png')}/>
                <Slide image={require('./img/banner2.png')}/>
                <Slide image={require('./img/banner3.png')}/>
            </Flickity>

            <div className='banner_wrapper'>
                <div className='button_wrapper'>
                    <AppointmentButton />
                </div>

                {/* <div className="banner">

                </div> */}
            </div>

        </div>
    )
}
export default Slider

