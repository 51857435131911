import React, { useRef } from "react"
import './style.css'
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';

function CertificateSlider({ certificates }: { certificates: string[] }) {

    const openImageInNewTab = (item:string) => {
        window.open(item, '_blank');
    };
    const flickityRef = useRef<Flickity | null>(null);
    return (
        <div className='certficate_wrapper'>

            <Flickity
                className="'certficate_carousel"
                options={{
                    initialIndex: 2,
                    prevNextButtons: true,
                    pageDots: false,
                    contain: true,
                    cellAlign: 'center',
                    adaptiveHeight: false,
                    autoPlay: 2000,
                    wrapAround: true,
                    draggable: false,

                }}
                flickityRef={(ref) => (flickityRef.current = ref)}
            >
                {certificates.map((item, index) => (
                    <div className="certficate_cell" key={index} onClick={()=>openImageInNewTab(item)}>
                        <img src={item} alt=" "/>
                    </div>
                ))}
                {/* <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div>
                <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div>
                <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div>
                <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div>
                <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div>
                <div className="certficate_cell" onClick={openImageInNewTab}>
                    <img src={require('../img/certficate.jpg')} alt="Certificate" />
                </div> */}




                {/* {priceList.map((item, index) => (
                <div className={`carousel-cell ${activeCardIndex === index} ? 'active' : ''}`}
                    onClick={() => handleCardClick(index)}>
                    <ServiceCard item={item} />
                </div>
            ))} */}



            </Flickity>
        </div>
    )
}

export default CertificateSlider