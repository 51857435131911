import React from "react";
import './style.css'

function Slide({ image }: { image: string }) {
    return (
        <div className='slider'>
            <div className="slider_cover" />
            <img src={image} alt="" />
        </div>

    )
}

export default Slide