import React from "react";
import './ProblemIcon.css'

type ProblemIconProps = {
    id: string;
    icon: string;
    title: string;
};

function ProblemIcon({ id, icon, title }: ProblemIconProps) {
    return (
        <div className="problem_card">
            <div className="problem_card_icon">
                <img src={icon} className='problem_icon' alt="" />
            </div>
            <p>{title}</p>
        </div>
    )

}

export default ProblemIcon;