import React from "react";
import './style.css'
import { diagnostics, priceList } from "./config";
import TableLine from "./components/tableLine/TableLine";
import DiagnosticCard from "./components/diagnosticCard/DiagnosticCard";
import AppointmentButton from "../../components/appointmentButton/AppointmentButton";
import ServicesSlider from "./components/servicesSlider/ServicesSlider";

function ServicesPage() {
    return (
        <div className="services_wrapper">
            <div className="services">
               <ServicesSlider/>
            </div>
            <div className="diagnostic_wrapper" id='diagnostic-section'>
                <p className="blok_title" id="diagnostic">Диагностики</p>
                <div className="diagnostic_cards">

                    {diagnostics.map((item, index)=>(
                        <DiagnosticCard key={index} item={item}/>
                    ))}

                </div>

            </div>
          
            <div className="price_wrapper" id="cost-section">
                <p className="blok_title">Стоимость услуг</p>
                {priceList.map((item, index) => (
                    <TableLine key={index} item={item} index={index} />
                ))}
                
            </div>
            <div className="button_wrapper">
            <AppointmentButton/>
            </div>

        </div>
    )
}

export default ServicesPage