export interface PriceListType {
  service: string;
  time: string;
  price: string;
}

export interface DiagnosticListType {
  diagnostic: string;
  items: string[];
  // cases: string,
  description?: string[];
}

export const diagnostics: DiagnosticListType[] = [
  {
    diagnostic: "Диагностика привязанности ребенка к членам своей семьи",
    items: [
      "Данная диагностика проводится в случае определения места жительства ребенка в ситуации развода родителей и проживания их в разных местах",
      "В случае определения порядка встреч с отдельно проживающим родителем (частота, время, место встреч, возможность ночёвок и т.д)",
      "В случае лишения родительских прав одного из родителей.",
    ],
    description: [
      "Благодаря данному тестированию можно выяснить с кем из родителей ребенок чувствует себя более комфортно и спокойно, с кем он сам хотел бы жить.",
    ],
  },
  {
    diagnostic: "Диагностика психоэмоционального состояния",
    items: [
      "На основании результатов данной диагностики, проведенной после встреч с отдельно проживающим родителем, можно делать вывод о психоэмоциональном состоянии ребенка, для того чтобы определить, не являются ли эти встречи стрессовыми для него",
      "скрининг психоэмоционального состояния малыша после определения места жительства ребенка",
      "также этот вид диагностики проводится в случаях буллинга в школе со стороны одноклассников и/или учителей",
      "выявление возможного негативного влияния на психоэмоциональное состояние ребенка посещение секций и кружков, атмосферы в детском саду и т.д.",
    ],
  },
  {
    diagnostic: "Психологическая диагностика познавательных процессов",
    items: [
      "В первую очередь проводится для определения готовности ребенка к школе, если у родителей возникают сомнения отдавать малыша в школу с шести или с семи лет",
      "В случаях, когда ребенок не посещает дошкольное учреждение и есть необходимость определить уровень его познательных процессов и накопленных в домашних условиях знаний.",
    ],
    description: [
      "Результаты данного тестирования помогут поставить точку в споре между родителями (в том числе и в судебном порядке), если один из них против посещения ребенком дошкольного учреждения, а второй родитель хотел бы чтобы ребенок ходил в детский сад.",
    ],
  },
  {
    diagnostic: "Диагностика стилей родительского воспитания",
    items: [
      "Поможет определить стили  воспитания мамы и папы (авторитарный, либеральный, опекающий, демократичный, отчужденный, хаотичный)",
      "Выявив стиль воспитания каждого из родителей или потенциального опекуна, можно предсказать, с кем из них ребенок будет развиваться более гармонично.",
      "понять, не нанесет ли вред несовершеннолетнему своейственный родителю стиль воспитания, что точно происходит, когда родитель преверженец авторитарного, отчужденного или хаотичного стиля",
      "выяснить эффективность, плюсы и недостатки доминирующего стиля воспитания в вашей семье, ведь каждый из этих стилей оказывает влияние на психику и формирование личности ребёнка",
      "на основании полученных результатов, возможно корректировать свой стиль с помощью специалиста",
    ],
  },
  {
    diagnostic: "Оценка и анализ информационных материалов",
    items: [
      "Центр предоставляет такую услугу, как анализ текстовых материалов и мультимедийных носителей информации на предмет выявления способности родителей к соответствующему интересам ребенка осуществлению своих родительских прав и обязанностей",
      "Исследования текстовых информационных материалов и мультимедийных носителей информации на предмет наличия в них признаков негативного психологического воздействия",
      "психологи Центра участвуют в качестве специалиста в судебных процессах с целью разъяснения заключений, ответов на все вопросы, касающиеся диагностического заключения, постановки вопросов для назначения психологических экспертиз",
    ],
  },
  {
    diagnostic:
      "Психодиагностика кризисных состояний\n(Психодиагностика актуального состояния)",
    items: [
      "непосредственно в момент переживания кризиса при обращении за помощью (например: переживания техногенной/природной катастрофы, оценка психологического состояния  после автоаварии с целью взыскания морального вреда и т.д.)",
      "ретроспективно (воспроизведение состояния в период переживания кризиса - проводится, когда есть необходимость исследовать психологического состояния человека в момент травмирующей ситуации, которая происходила в прошлом)",
      "лиц с признаками прогностически неблагоприятного переживания кризиса, выявленных в группах риска (сенситивные возрастные периоды, сложные жизненные ситуации и др.)",
      "в процессе динамики психического состояния в период переживания кризиса",
    ],
  },
  {
    diagnostic:
      "Психодиагностика кризисных состояний\n(Изучение индивидуально-личностных характеристик, влияющих на протекание кризиса, т.е. психологических факторов)",
    items: [
      "провоцирующих кризис",
      "влияющих на интенсивность и особенности эмоциональной окраски переживания кризисов",
      "способствующих благоприятному протеканию и позитивному выходу из кризисов",
      "прогностически значимых для неблагоприятных исходов психологических кризисов",
    ],
    description: [
      "Эти исследования проводятся с целью выяснить, насколько личностные характеристики самого обследуемого влияют на восприятие и течение травмирующей ситуации.",
      "А также выявление факторов психологического, физического, сексуального насилия в случаях, когда клиент не может или не хочет говорить об этом.",
      "Психодиагностическое обследование эмоционального состояния лица после причинения ему предполагаемого (заявляемого) морального вреда",
    ],
  },
];

export const carouselServises = [
  "Индивидуальная консультация",
  "Парная/семейная консультация",
  "Диагностика психоэмоционального состояния",
  "Диагностика стилей родительского воспитания",
  "Диагностика привязанности ребенка к родителям",
  "Другие диагностики по запросу",
  "Бизнес-коучинг",
  "Группа психологической поддержки",
  "Линейка мастер - классов для специалистов",
  "Тимбилдинг – по запросу"
];

export const priceList: PriceListType[] = [
  {
    service: "Индивидуальная консультация",
    time: "60мин",
    price: "140 BYN",
  },
  {
    service: "Парная/семейная консультация (два терапевта)",
    time: `90 мин`,
    price: "200 BYN",
  },
  {
    service: "Парная/семейная консультация (один терапевт)",
    time: `90 мин`,
    price: "200 BYN",
  },
  {
    service: "Диагностика психоэмоционального состояния",
    time: "*",
    price: "270 BYN",
  },
  {
    service: "Диагностика стилей родительского воспитания ",
    time: "*",
    price: "260.00 BYN",
  },
  {
    service: "Диагностика привязанности ребенка к родителям (дети до 10 лет)",
    time: "*",
    price: "360 BYN",
  },
  {
    service:
      "Диагностика привязанности ребенка к родителям (дети старше 10 лет)",
    time: "*",
    price: "460 BYN",
  },
  {
    service: "Другие диагностики по запросу ",
    time: "*",
    price: "от 270.00 BYN",
  },
  {
    service: "Бизнес-коучинг",
    time: "60 мин",
    price: "150 BYN",
  },
  {
    service: "Группа психологической поддержки",
    time: "180 мин",
    price: "60 BYN",
  },
  {
    service: "Линейка мастер - классов для специалистов",
    time: "*",
    price: "*",
  },
  {
    service: "Тимбилдинг – по запросу",
    time: "*",
    price: "(от времени проведения)",
  },
];

export enum classNames {
  even = "table-line",
  odd = "table-line-odd",
}
