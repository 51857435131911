import React from "react";
import './style.css'
import payment from './img/1.png'
import MyMap from "./components/map/MyMap";
import Links from "../links/Links";

function Footer() {
    return (
        <div className="footer_container">
            <div className="footer">
                <div className="left_footer_parth">
                    <div className="info_box">
                        <p className="footer_title">Расположение центра</p>
                        <p className="info_text">ул. Платонова, д. 22, оф. 405,<br />г.Минск, Беларусь 220005<br /> felicia29@mail.ru</p>

                    </div>
                    <div className="info_box">
                        <p className="footer_title">Время работы:</p>
                        <p className="info_text">пн-пт 9.00 - 21.00<br />суббота 9.00 - 18.00</p>
                    </div>
                   
                    
                    <Links />
                    <a href="/oferta.pdf" target="_blank" className="doc_link">Публичный договор оферты</a>
                    <div className="info_box">
                        {/* <p className="footer_title">Время работы:</p> */}
                        <p className="info_small-text">ООО "Центр психологической помощи и поддержки семьи Крылья мечты", УНП 193773048 выдано 25.06.2024 Минским Горисполкомом. <br/>
                        Беларусь, Первомайский район, г. Минск, ул. Платонова, дом 22, оф. 405<br/>
                        Адрес для корреспонденции: г. Минск, Ул. Брестская 74-60<br/>
                        BY18 ALFA 3012 2F21 4900 1027 0000 в BYN, в "АльфаБанк", БИК: ALFABY2X<br/>
                        </p>
                        <img src={payment} alt="" className="payment_logos"/>
                       
                    </div>
                </div>
                <div className="right_footer_parth">
                    <MyMap />
                </div>
            </div>

        </div>
    )
}

export default Footer

