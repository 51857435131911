import React from "react";
import './style.css'
import Slider from "./components/slider/Slider";
import DropdownBox from "./components/dropdown/DropdownBox";
import { directions } from "../../data/directions";
import Splitter from "../../components/spliter/Splitter";
import ProjectsCard from "./components/projects/ProjectsCard";
import { recentProjects } from "../project/config";
import AppointmentButton from "../../components/appointmentButton/AppointmentButton";
import Table from "./components/table/Table";
import PsychoCarousel from "./components/carousel/PsychoCarousel";

function MainPage() {


    return (
        <div className="main_wrapper">

            <Slider />
            {/* <div className="main"> */}
            <div className="main_info">
                <div className="greeting_box">
                    <p>Добро пожаловать на сайт</p>
                    <p className="title">Крылья мечты</p>
                </div>
                <div className="greeting_text">
                    <p>Мы рады приветствовать Вас на нашем сайте. Здесь вы найдете место, где каждая ваша эмоция, мысль и стремление обретут поддержку и внимание. Наш центр предлагает профессиональную помощь и ресурсы для того, чтобы помочь вам достичь психологического благополучия и личностного роста.</p>
                    <p>Мы осознаем, что жизнь может представлять нам различные вызовы, которые иногда трудно преодолеть самостоятельно. В нашем центре мы создали комфортную и поддерживающую атмосферу, где вы можете чувствовать себя в безопасности и открыто общаться о своих переживаниях. Наши профессиональные психологи обладают глубокими знаниями и опытом, чтобы помочь вам понять свои эмоции, разрешить конфликты, преодолеть стресс и найти пути к личностному росту.</p>
                    {/* <p>Наш подход основан на индивидуальности каждого клиента. Мы уверены, что каждый человек уникален, и именно поэтому мы разрабатываем индивидуальные программы и стратегии, соответствующие вашим уникальным потребностям и целям. Мы стремимся создать доверительные и эмпатичные отношения, чтобы помочь вам раскрыть свой полный потенциал и стать более уверенными и счастливыми.</p> */}
                </div>
                <AppointmentButton />
                <Table />
                <PsychoCarousel />
                <div className="recent_projects_blok">
                    <p className="blok_title" id='projects'>Последние проекты</p>
                    <Splitter />
                    <div className="projects_cards">
                        {recentProjects.map((item) => (
                            <ProjectsCard key={item.id} project={item} />
                        ))}
                    </div>

                </div>
                <div className="directions_wrapper">
                    <p className="directions">Основные направления нашей деятельности</p>
                    <p>Наряду с психологическими диагностиками к нам можно обратиться со следующими запросами:</p>
                    <div className="dropdowns_wrapper">
                        {directions.map((item) => (
                            <DropdownBox item={item} />
                        ))}
                    </div>
                    {/* <DropdownBox /> */}
                </div>
                <div className="partners_blok">
                    <p className="blok_title" id='partners'> Наши партнеры</p>
                    {/* <Splitter /> */}
                    <div className="partners_cards">
                        <div className="partners_card">
                            <img src={require('./img/tochka.jpg')} alt="" />

                        </div>

                        <div className="partners_card">
                            <img src={require('./img/sos.jpg')} alt="" />

                        </div>
                        <div className="partners_card">
                            <img src={require('./img/103by.jpg')} alt="" />
                        </div>
                        <div className="partners_card">
                            <img src={require('./img/relaxby.jpg')} alt="" />
                        </div>
                        <div className="partners_card">
                            <img src={require('./img/fiz_ra.jpg')} alt="" />
                        </div>
                        {/* {recentProjects.map((item) => (
                            <ProjectsCard key={item.id} project={item} />
                        ))} */}
                    </div>

                </div>




            </div>

        </div>
    )
}
export default MainPage