import React from "react";
import './style.css'


function ServiceCard({ item }: { item: string }) {
    return (
        <div className="service_card">
            <div className="service_card-cover"/>
            <img src={require('./img/cardImage.jpg')}  alt=""/>
            <p>{item}</p>
            
        </div>
    )
}

export default ServiceCard