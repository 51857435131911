import React, { useEffect, useState } from "react";
import './style.css'
import Modal from 'react-modal';
import { useAppSelector } from "../../../../store/hooks";
import { PsychologistType } from "../../types";
import { findPsychologistById } from "../../helpers";
import { AiOutlineCloseCircle } from "react-icons/ai";
import CertificateSlider from "../certificateSlider/CertificateSlider";


const modalStyles: ReactModal.Styles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 10
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        height: '70%',
        margin: 'auto',
        // border: '1px solid #ccc',
        borderRadius: '11px',
        padding: '3%',

    },

};

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
}

function CustomModal({ isOpen, onClose }: ModalProps) {

    const id = useAppSelector((state) => state.psychID.id)
    const [psychologist, setPsychologist] = useState<PsychologistType>()

    useEffect(() => {
        if (id) {
            setPsychologist(findPsychologistById(id))
        }

    }, [id])

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={modalStyles}
            ariaHideApp={true} >

            <div className="close_icon" onClick={onClose}>
                <AiOutlineCloseCircle size={30} />
            </div>
            {/* </div> */}
            <div className="modаl_body">
                <div className="image_box">
                    <img src={psychologist?.photo[0]} alt=""/>
                </div>
                <div className="specifications">
                    <h3>{psychologist?.name}</h3>
                    <ul className="specifications">
                        {psychologist?.specifications.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                    {psychologist && psychologist?.certificates.length != 0 && (
                        <>
                            <h3>Сертификаты</h3>
                            <CertificateSlider certificates={psychologist.certificates}/>
                        </>
                    )}

                </div>
            </div>
            <div className="work_areas">
                {/* {psychologist?.workAreas} */}
                <h3 id='work_areas_title'>Направления работы</h3>
                <ol type="1" className="work_areas">
                    {psychologist?.workAreas.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ol>

            </div>

            {/* <button onClick={onClose}>Закрыть</button> */}
        </Modal>
    )
}

export default CustomModal