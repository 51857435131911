import React, { useEffect, useRef, useState } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import './style.css'
import { carouselServises } from '../../config';
import ServiceCard from '../serviceCard/ServiceCard';

function ServicesSlider() {
    const flickityRef = useRef<Flickity | null>(null);
    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);

    useEffect(() => {
        setActiveCardIndex(2);
        flickityRef.current?.reloadCells();

        return () => {
            flickityRef.current?.destroy();
        };
    }, []);

    const handleCardClick = (index: number) => {
        flickityRef.current?.select(index);
        setActiveCardIndex(index);
    };

    return (
        <div className='carousel_wrapper'>
            <p className='blok_title'>Услуги</p>
            <Flickity
                className="carousel"
                options={{
                    initialIndex: 2,
                    prevNextButtons: true,
                    pageDots: false,
                    contain: true,
                    cellAlign: 'center',
                    adaptiveHeight: false,
                    autoPlay: false,
                    wrapAround: true,
                    draggable: false,

                }}
                flickityRef={(ref) => (flickityRef.current = ref)}
            >
                {carouselServises.map((item, index) => (
                    <div className={`carousel-cell ${activeCardIndex === index} ? 'active' : ''}` }
                        onClick={() => handleCardClick(index)}>
                        <ServiceCard item={item} />
                    </div>
                ))}



            </Flickity>
        </div>
    );
};

export default ServicesSlider;