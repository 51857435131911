export interface DirectionsType{
    type: string,
    descriptions: string[]
}

// export interface ProblemsType{
//     id: string,
    
// }

export const directions : DirectionsType[] = [
    {
        type: 'Индивидуальные консультации',
        descriptions: ['саморазвитие и поиск себя','неуверенность в себе', 'недовольство собой', 'эмоциональное выгорание', 'возрастные кризисы', ' разрешение конфликтов, улучшение навыков коммуникации', 'адаптация при переезде/ смене работы', 'выстраивание личных границ', 'повышение самооценки', 'кризисное консультирование (страх, отчаяние, насилие, панические атаки, потеря близкого человека/ развод, отвержение окружающими)', 'консультация сексолога по вопросам интимного характера']
        
    },
    {
        type: 'Парное консультирование',
        descriptions: ['консультация пар на стадии становления пары, создания семьи и становления пары, в период развода и пост-разводный период', 'медиация интернациональных и многонациональных пар (помощь в ситуации семейного кризиса)', 'выстраивание гармоничных границ внутри пары', 'консультация сексолога']
        
    },
    {
        type: 'Работа с детьми и подростками и консультации для родителей',
        descriptions: ['диагностика и коррекция возрастных кризисов', 'развитие эмоционального интеллекта', 'адаптация к школе', 'повышение самооценки', 'обучение навыков решения конфликтных ситуаций', 'самоопределение', ' коррекция девиантного поведения (агрессия, аутоагрессия, курение, пропуски школы и т.д.)', 'работа по запросам: тревожность, страхи, энурез, буллинг и т.д.', 'определение стиля воспитания с последующей коррекцией (по запросу)', 'коррекция коммуникативных навыков с детьми и в родительской паре', 'семейные и детские кризисы', 'личные консультации по запросу', ' консультация семей, решивших усыновить ребенка. Психологическое сопровождение на каждом этапе: от момента принятия решения, до появления малыша в семье и преодоления кризисных моментов']
        
    },
    // {
    //     type: 'Консультации для родителей',
    //     descriptions: ['определение стиля воспитания с последующей коррекцией (по запросу)', 'коррекция коммуникативных навыков с детьми и в родительской паре', 'семейные и детские кризисы', 'личные консультации по запросу', ' консультация семей, решивших усыновить ребенка. Психологическое сопровождение на каждом этапе: от момента принятия решения, до появления малыша в семье и преодоления кризисных моментов']
        
    // },
    {
        type: 'Бизнес-коучинг',
        descriptions: ['постановка целей', 'сопровождение и консультации на всех этапах достижения целей', 'повышение личной эффективности', 'профориентация (при желании смены сферы деятельности и/или профессии)', 'медиация в процессе конфликтных ситуаций в рабочем коллективе', 'сопровождение на переговорах', 'процесс построения адаптации сотрудников', 'тим-билдинг']
        
    },
]

// export const problemsArr = {


// }
