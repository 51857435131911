import React from "react";
import "./style.css"

function MyMap() {
    return (
        <div className="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2350.184196597478!2d27.593901139969088!3d53.91070267292169!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbcfae637eca57%3A0xced63baf2b4c03e7!2z0YPQuy4g0J_Qu9Cw0YLQvtC90L7QstCwIDIyLCDQnNC40L3RgdC6LCDQnNC40L3RgdC60LDRjyDQvtCx0LvQsNGB0YLRjA!5e0!3m2!1sru!2sby!4v1717008845764!5m2!1sru!2sby" className="map" title="map">
            </iframe>
        </div>
    )
}
export default MyMap